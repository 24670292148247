import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import { useRef } from 'react'
import upsellImg from '../../assests/admin/assets/img/HL-ZComplete.png';
import upsellImg2 from '../../assests/admin/assets/img/z-complete-sleep-bottles.png';
import orderBtn from '../../assests/admin/assets/img/add-to-order-animated.gif';
import { useLocation, useNavigate, useParams }  from 'react-router-dom';
import Copyright from '../Copyright';
import useStore from '../../store';
import FrontLogin from '../frontend/FrontLogin';
import AccessDeniedPage from "../AccessDeniedPage";

function Upsell() {
    const [disable,setDisable] = useState(false);
    const [loadershow, setloadershow] = useState(false);
    const location = useLocation();
    let params = location.search;
    let userDetails = useStore((state) => state.userDetails);
    const isLogin = useStore((state) => state.isLogin);
    const navigate = useNavigate();
    const afterLogin = useStore((state) => state.afterLogin);
    const params2 = useParams();

    async function createMember(orderId,orderTotal,productId){
        localStorage.setItem('neobodPId',665);
        localStorage.setItem('neobodCid',668);
        const formdata = new FormData();
        formdata.append('firstName', localStorage.getItem('firstName'));
        formdata.append('lastName', localStorage.getItem('lastName'));
        formdata.append('billingAddress1', localStorage.getItem('shippingAddress1'));
        formdata.append('billingZip', localStorage.getItem('shippingZip'));
        formdata.append('billingState', localStorage.getItem('shippingState'));
        formdata.append('billingCity', localStorage.getItem('shippingCity'));
        formdata.append('phone', localStorage.getItem('phone'));
        formdata.append('email', localStorage.getItem('email'));
        formdata.append('billingCountry', localStorage.getItem('country'));
        formdata.append('billingCountry', localStorage.getItem('country'));
        formdata.append('orderId', orderId);
        formdata.append('orderTotal', orderTotal);
        formdata.append('productId', productId);
        formdata.append('is_membership', localStorage.getItem('is_membership'));
        formdata.append('method', localStorage.getItem('method'));
        setDisable(true);
        setloadershow(true);
        axios.post(`api/create-member-by-paypal`, formdata).then(res => {
            console.log(res.data.data);
            localStorage.setItem('userId',res.data.data.user_id);
            localStorage.setItem('userEmal',res.data.data.email);
            localStorage.setItem('membership_orderId', res.data.membership_orderId ? res.data.membership_orderId : false);
            let is_membership = 0;
            if(parseInt(res.data.data.is_membership) === 1){
                is_membership = 1;
            } else {
                is_membership = 0;
            }
            afterLogin(res.data.data.account_permission, res.data.data.user_type,res.data.data.user_id,res.data.data,is_membership);
            setDisable(false);
            setloadershow(false);
        });
    }

    useEffect(()=>{
        const urlParams = new URLSearchParams(window.location.search);
        let responseCode = urlParams.get('responseCode');
        let orderId = urlParams.get('orderId');
        let orderTotal = urlParams.get('orderTotal');
        let productId = urlParams.get('line_items[0][product_id]');
        localStorage.setItem('method','normal');
        setloadershow(true);
        if(responseCode == 100){
            localStorage.setItem('orderId',orderId);
            localStorage.setItem('method','paypal');
            if(isLogin != 1){
                //if(localStorage.getItem('is_membership') == 41){
                    const timer = setTimeout(() => {
                        createMember(orderId,orderTotal,productId);
                    }, 1000);
                    return () => clearTimeout(timer);
                //}
            } else {
                setloadershow(false);
            }
        } else if(responseCode == 800) {
            localStorage.removeItem('prospectId');
            axios.post(`api/get-pending-order-details`, {'order_id':orderId}).then(res => {
                console.log(res.data);
                setloadershow(false);
                if(res.data.affid != '' & res.data.sid != '' & res.data.c1 != '' & res.data.c2 != '' & res.data.c3 != '' & res.data.click_id != ''){
                   window.location.href = '/'+localStorage.getItem('c_s')+'?affid='+res.data.affid+'&sid='+res.data.sid+
                    '&s1='+res.data.c1+'&s2='+res.data.c2+'&s3='+res.data.c3+'&click_id='+res.data.click_id;
                } else {
                    setloadershow(false);
                    window.location.href = '/'+localStorage.getItem('c_s');
                }  
            });
            
        } else {
            setloadershow(false);
        }
        
    },[params2.slug]);

    function upsellProcced(){
        setDisable(true);
        setloadershow(true);
        const formdata = new FormData();
        formdata.append('first_name', localStorage.getItem('firstName'));
        formdata.append('last_name', localStorage.getItem('lastName'));
        formdata.append('email', localStorage.getItem('email'));
        formdata.append('is_membership', 0);

        /* local db upsell product id set according to checkout slug */

        if((localStorage.getItem('c_s') === 'brain4-checkout') || (localStorage.getItem('c_s') === 'checkout-b')){
            formdata.append('product_id', 152);
        } else if(localStorage.getItem('c_s') === 'brain10p-checkout') {
            formdata.append('product_id', 168);
        } else if(localStorage.getItem('c_s') === 'nootropic2p-checkout') {
            formdata.append('product_id', 185);
        } else if(localStorage.getItem('c_s') === 'brain15p-checkout') {
            formdata.append('product_id', 236);
        } else if(localStorage.getItem('c_s') === 'brain20p-checkout') {
            formdata.append('product_id', 245);
        } else if(localStorage.getItem('c_s') === 'synaptic-checkout') {
            formdata.append('product_id', 261);
        } else {
            formdata.append('product_id', 45);
        }

        /* local db upsell product id set according to checkout slug */
        
        formdata.append('is_upsell', 1);
        formdata.append('order_id', localStorage.getItem('orderId'));
        formdata.append('user_id',(userDetails) ? userDetails.user_id : localStorage.getItem('userId'));
        formdata.append('method',localStorage.getItem('method'));
        if(localStorage.getItem('funnel')){
            formdata.append('funnel',localStorage.getItem('funnel'));
        }
        //console.log(Object.fromEntries(formdata));
        if(localStorage.getItem('orderId')){
            axios.post(`api/place-upsell-order`, formdata).then(res => {
                if (res.data.status === true) {
                    //console.log(res.data.data);
                    if(localStorage.getItem('up2_s') === 'focus-offer'){
                        localStorage.setItem('Upsell2_orderId', res.data.data.order_id);
                        if(localStorage.getItem('method') == 'paypal'){
                            window.location.href = '/'+localStorage.getItem('con_s')+params;
                        } else {
                            if(localStorage.getItem('up3_s')){
                                window.location.href = '/'+localStorage.getItem('up3_s')+params;
                            } else {
                                window.location.href = '/'+localStorage.getItem('con_s')+params;
                            }
                        }
                    } else {
                        localStorage.setItem('Upsell1_orderId', res.data.data.order_id);
                        if(localStorage.getItem('up2_s')){
                            window.location.href = '/'+localStorage.getItem('up2_s')+params;
                        }
                        else{
                            window.location.href = '/'+localStorage.getItem('con_s')+params;
                        }
                    }
                }
                else{
                    setDisable(false);
                    setloadershow(false);
                    Swal.fire({
                        title: res.data.data.decline_reason,
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33'
                    })
                    
                }
            })
        } else {
            Swal.fire({
                title: 'Order Decline',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = '/dashboard';
                }
            })
        }
    }

    function goToConfirmation(){
        setloadershow(true);
        if(localStorage.getItem('up2_s') === 'focus-offer'){
            if(localStorage.getItem('method') == 'paypal'){
                window.location.href = '/'+localStorage.getItem('con_s')+params;
            } else {
                if(localStorage.getItem('up3_s')){
                    window.location.href = '/'+localStorage.getItem('up3_s')+params;
                } else {
                    window.location.href = '/'+localStorage.getItem('con_s')+params;
                }
            }
        } else {
            if(localStorage.getItem('up2_s')){
                window.location.href = '/'+localStorage.getItem('up2_s')+params;
            }
            else{
                window.location.href = '/'+localStorage.getItem('con_s')+params;
            }
        }
    }

    /*return (
        <>
            { isLogin === 1 ?
            <>
            <div className={loadershow ? 'loading' : 'd-none'}></div>
            <div className="main-page upsellPage">  
                <div className='sticky-red-top text-center'>
                    <h2>ORDER NOT COMPLETE - DO NOT CLOSE THIS PAGE!</h2>
                </div>   
                <div className='upsellMain mt-3 mt-md-5'>                    
                    <div className=' container'>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-6 text-center align-middle'>
                                <figure>
                                    <img src={upsellImg} className="upsellImg img-fluid" />
                                </figure>
                            </div>
                            <div className='col-sm-12 col-lg-6 text-center align-middle m-auto'>
                                <h1>TRY OUR SLEEP FORMULA</h1>
                                <p >
                                    <span className="font-weight-bold">Our amazingly powerful Sleep Formula will provide the concentration and mental clarity you need to cut out all the distractions and get the job done!</span><br />
                                    <br />
                                    <span className="font-weight-bold">And for a great night sleep to help your mind repair and recharge, do not forget to pick up Z-Complete Sleep Formula!</span>
                                    <br />
                                    <span>We are so sure you'll fall in love with our Sleep Formula, we are offering a one-time <span className="font-weight-bold">SPECIAL DISCOUNT OF 50% OFF IF YOU TRY IT TODAY!</span></span>
                                </p>
                                <p className="mt-4">
                                    <span className="font-weight-bold">ADD TO YOUR ORDER FOR ONLY $34.95!</span>
                                </p>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-12 text-center'>
                                <div className='text-center'>
                                    <span id="button_focus">
                                        <input type="image" src={orderBtn} className="img-fluid" disabled={(disable)?'disabled':''} onClick={upsellProcced} />
                                    </span>
                                </div>  
                                <div className="text-center">
                                    <div className="form-horizontal bottom-nothanks">
                                        <button onClick={goToConfirmation} className="btn btn-sm btn-danger p-3 mb-5">
                                            <span class="cross text-danger">X</span> No thanks! I don't want to save 50% on the sleep formula today.
                                        </button>
                                    </div>
                                </div>                              
                            </div>
                        </div>
                    </div>
                </div>
                <Copyright/>  
            </div>
            </>
            : <><div className={loadershow ? 'loader-new loading' : 'd-none'}></div><AccessDeniedPage/> </>}
        </>
    )*/
    return (
        <>
           {isLogin === 1 ? (
            <>
                <div className={loadershow ? 'loader-new loading' : 'd-none'}></div>
                    <div className="main-page upsellPage focusOffer-page">
                        <div className='sticky-red-top text-center'>
                        <h2>ORDER NOT COMPLETE - DO NOT CLOSE THIS PAGE!</h2>
                    </div>
                    <div className='upsellMain mt-3 mt-md-5'>
                        <div className=' container'>
                            <div className='row'>
                                <div className='col-sm-12 col-lg-6 text-center align-middle'>
                                    <figure>
                                        {localStorage.getItem('c_s') == ('brain20p-checkout') ||  ('synaptic-checkout') ? 
                                            <img src={upsellImg2} className="upsellImg img-fluid" />
                                        :
                                            <img src={upsellImg} className="upsellImg img-fluid" />
                                        }
                                    </figure>
                                </div>
                                <div className='col-sm-12 col-lg-6 text-center align-middle m-auto'>
                                    <h1>TRY OUR SLEEP FORMULA</h1>
                                    <p >
                                        <span className="font-weight-bold">Our amazingly powerful Sleep Formula will provide the concentration and mental clarity you need to cut out all the distractions and get the job done!</span><br />
                                        <br />
                                        <span className="font-weight-bold">And for a great night sleep to help your mind repair and recharge, do not forget to pick up Z-Complete Sleep Formula!</span>
                                        <br />
                                        <span>We are so sure you'll fall in love with our Sleep Formula, we are offering a one-time <span className="font-weight-bold">SPECIAL DISCOUNT OF 50% OFF IF YOU TRY IT TODAY!</span></span>
                                    </p>
                                    <p className="mt-4">
                                        <span className="font-weight-bold">ADD TO YOUR ORDER FOR ONLY {localStorage.getItem('c_s') == ('brain20p-checkout') || ('synaptic-checkout') ? '$58.94!' : '$34.95!'}</span>
                                    </p>
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-12 text-center'>
                                    <div className='text-center'>
                                        <span id="button_focus">
                                            <input type="image" src={orderBtn} className="img-fluid" disabled={(disable)?'disabled':''} onClick={upsellProcced} />
                                        </span>
                                    </div>  
                                    <div className="text-center">
                                        <div className="form-horizontal bottom-nothanks">
                                            <button onClick={goToConfirmation} className="btn btn-sm upsell p-3 mb-5">
                                                <span class="cross text-danger">X</span> No thanks! I don't want to save 50% on the sleep formula today.
                                            </button>
                                        </div>
                                    </div>                              
                                </div>
                            </div>
                        </div>
                    </div>
                <Copyright/>
              </div>
            </>
            ) : (
            <>
              <div className={loadershow ? 'loader-new loading' : 'd-none'}></div>
              <AccessDeniedPage/>
            </>
          )}
        </>
      );
}



export default Upsell